import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import React from "react"

const useStyles = makeStyles({
  root: {
    background: '#fe66cb',
    border: 0,
    borderRadius: 0,
    boxShadow: '8px 6px #fe3324',
    color: 'white',
    padding: '0 30px',
    fontWeight: '900',
    fontStyle: 'italic',
    textTransform: 'none',
    fontSize: `24px`,
    zIndex: `1000`,
    marginTop: `-35px`,
    maxWidth: `300px`
  },
});

const LuxBtn = ({text, fontSize, disabled}) => {
  const classes = useStyles();
  return (
    <Button variant="contained" color="secondary" className={classes.root} style={{fontSize: fontSize}} disabled={disabled}>
      {text}
    </Button>
  )
}

export default LuxBtn