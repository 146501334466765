import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import CoolGrid from '../components/coolGrid'

const useStyles = makeStyles((theme) => ({
  sandwich: {
    height: `0px`,
    transform: `translateY(-35px)`,
    paddingLeft: `10%`,
    zIndex: 99
  },
  wrapper: {
    backgroundColor: `#ffffff`,
    paddingTop: `2rem`,
    paddingBottom: `2rem`,
    flex: `1 1 auto`,
    display: `flex`,
    marginTop: `-1rem`,
    flexDirection: `column`,
    justifyContent: `center`,
    zIndex: `999`
  },
  //wrapperBottom: {
  //  backgroundColor: `rgb(240,229,215)`,
  //  paddingBottom: `4rem`,
  //},
  staffContainer: {
    transition: `all 0.3s ease`,
  },
  text: {
    color: `rgb(254,255,255)`
  },
  minis: {
    paddingTop: `30vh`
  },
  btnWrapper: {
    display: `flex`,
    justifyContent: `flex-end`,
    marginTop: `15px`,
    padding: `35px`,
    height: `100%`
  },
  bigText: {
    color: `rgb(13,47,73)`
  },
  subBigText: {
    fontStyle: `italic`,
  }
}));

const Perform = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "GroupLUX.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Perform at LUX" description="No other school provides as many opportunities to shine than LUX performing arts! Every time an artist performs, they are one step closer to finding their confidence and authentic voice." />
      <BackgroundImage fluid={data.file.childImageSharp.fluid} className={classes.polkas} style={{backgroundSize: 'cover !important', backgroundRepeat: `no-repeat !important`, backgroundPosition: `center !important`, backgroundColor: `rgb(203, 230, 245)`}}>
        <div className={classes.minis}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 170">
            <path fill="#ffffff" fillRule="nonzero" fillOpacity="1" d="M 0 96 L 120 101.3 C 240 107 480 117 720 112 C 960 107 1200 85 1314 63 L 1441 36 L 1440 320 L 1320 320 C 1200 320 960 320 720 320 C 480 320 240 320 120 320 L 0 320 Z"></path>
            <path fill="rgb(239,178,91)" fillOpacity="1" d="M 0 96 L 120 101.3 C 240 107 480 117 720 112 C 960 107 1200 85 1314 63 L 1441 36 L 1442 50 L 1315 77 C 1137 105 1000 117 718 129 C 417 133 296 132 119 118 L 0 111 Z"></path>
            <path className="second-path" fill="rgb(242,203,144)" fillOpacity="1" d="M 0 96 L 120 101.3 C 240 107 480 117 720 112 C 960 107 1200 85 1314 63 L 1441 36 L 1442 50 L 1315 77 C 1137 105 1000 117 718 129 C 417 133 296 132 119 118 L 0 111 Z"></path>
            <path className="third-path" fill="rgb(244,236,209) " fillOpacity="1" d="M 0 96 L 120 101.3 C 240 107 480 117 720 112 C 960 107 1200 85 1314 63 L 1441 36 L 1442 50 L 1315 77 C 1137 105 1000 117 718 129 C 417 133 296 132 119 118 L 0 111 Z"></path>
          </svg>
        </div>
      </BackgroundImage>
      <Box component="section" className={classes.wrapper}>
        <Container maxWidth='md' className={classes.staffContainer}>
          <Typography variant="h2" component="h2" align="center" gutterBottom className={classes.bigText}>
            Upcoming performances
          </Typography>
          <Typography variant="h3" component="h3" align="center" gutterBottom className={classes.bigText, classes.subBigText}>
            Reserve tickets below
          </Typography>
        </Container>
      </Box>
      <CoolGrid />
    </Layout> 
  )
}

export default Perform