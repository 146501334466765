import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';
import PreviewCompatibleImage from './previewCompatibleImage'
import LuxBtn from '../components/luxBtn'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import ColorfulDvdr from './colorfulDvdr'

const useStyles = makeStyles((theme) => ({
  bricks: {
    lineHeight: `20px`,
    columnCount: 1,
    columnGap: `5px`,
    [theme.breakpoints.up('xs')]: {
      columnCount: 1,
    },
    [theme.breakpoints.up('sm')]: {
      columnCount: 2,
    },
    [theme.breakpoints.up('md')]: {
      columnCount: 3,
    },
    [theme.breakpoints.up('lg')]: {
      columnCount: 4,
    }
  },
  img: {
    width: `100% !important`,
    height: `auto !important`
  },
  video: {
    width: `100% !important`,
    height: `auto !important`,
    columnSpan: 2
  },
  gridWrapper: {
    backgroundColor: `rgb(16,16,17)`,
    height: `2000px`
  },
  textContainer: {
    paddingBottom: `36px`,
    color: `#ffffff`
  },
  iframeWrapper: {
    backgroundColor: `#ffffff`,
    paddingTop: `50px`
  },
  boxWrapper: {
    backgroundColor: `#ffffff`,
  },
  bigText: {
    color: `#fe66cb`,
  },
  subBigText: {
    fontStyle: `italic`,
    color: `#FFFFFF`
  },
  featuredImage: {
    boxShadow: '11px 10px rgb(233,114,199)',
  },
  upcomingTitleWrapper: {
    background: 'rgb(16,16,17)',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  auditionWrapper: {
    paddingTop: '2rem',
    paddingBottom: '8rem',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '5rem',
      paddingBottom: '5rem',
    },
  },
  btnWrapper: {
    paddingTop: `3rem`,
    paddingBottom: `3rem`,
  },
  luxButton: {
    background: '#fe66cb',
    border: 0,
    borderRadius: 0,
    boxShadow: '8px 6px #fe3324',
    color: 'white',
    padding: '1rem',
    fontWeight: '900',
    fontStyle: 'italic',
    textTransform: 'none',
    fontSize: `24px`,
    zIndex: `1000`,
    maxWidth: `300px`,
  },
  postDescription: {
    paddingTop: `2rem`,
  },
  postSubtitle: {
    paddingTop: `1rem`,
  }
}));

const CoolGrid = ({ contentType, img }) => {
  const classes = useStyles()
  const matchesSmall = useMediaQuery('(max-width:816px)')
  const matchesBig = useMediaQuery('(min-width:1327px)')

  const getIfrmHeight = () => {
    return matchesSmall ? `900px` : matchesBig ? `490px` : `490px`
  }

  const data = useStaticQuery(graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            author
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`)
const { edges: posts } = data.allMarkdownRemark
const latestPost = posts[0].node

  return (
    <>
      <Box className={classes.boxWrapper}>
        <Container maxWidth='md' className={classes.textContainer}>
          <div className={classes.iframeWrapper}>
            <iframe title={`omnify-widget`} src='https://luxperformingarts.getomnify.com/widgets/G76USZ32XS6PI1534UUVRE' height={getIfrmHeight()} width='100%' frameBorder='0'></iframe>
          </div>
        </Container>
      </Box>
      <Box className={classes.upcomingTitleWrapper}>
        <Container maxWidth="lg">
          <Typography variant="h2" component="h2" align="center" gutterBottom className={classes.bigText}>
            Upcoming Auditions
          </Typography>
          <Typography variant="h3" component="h3" align="center" gutterBottom className={classes.bigText, classes.subBigText}>
            Sign up below
          </Typography>
        </Container>
      </Box>
      <ColorfulDvdr />
      <Box className={classes.auditionWrapper}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Typography variant="h3" component="h3" className={classes.featuredPost}>
                {latestPost.frontmatter.title}
              </Typography>
              <Typography variant="h6" component="h6" className={classes.postSubtitle}>
                Audition for LUX Performing Arts upcoming productions of Matilda and Spring Awakening! 
              </Typography>
              <Typography className={classes.postDescription}>
                Audition for LUX Performing Arts Mainstage Summer Season! April 24th-25th. Sign up now!
              </Typography>
              <div className={classes.btnWrapper}>
                <AniLink paintDrip to={`/latestAtLux${latestPost.fields.slug}`} hex="#fe66cb">
                  <LuxBtn text={`SIGN UP HERE`} />
                </AniLink>
              </div>
            </Grid>
            <Grid item xs={12} md={7}>
              <AniLink paintDrip to={`/latestAtLux${latestPost.fields.slug}`} hex="#fe66cb">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: latestPost.frontmatter.featuredimage,
                    alt: `featured image for latest event`,
                  }}
                  className={classes.featuredImage}
                />
              </AniLink>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default CoolGrid